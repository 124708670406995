import React, { Suspense, useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

const MinecraftModel = ({ scale = [1, 1, 1], position = [0, 0, 0] }) => {
  const modelRef = useRef();
  const { scene } = useGLTF('../assets/3d/minecraft_village.glb');

  useFrame((state) => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.0001; // Slow rotation
    }
  });

  return (
    <primitive 
      ref={modelRef}
      object={scene} 
      scale={scale} 
      position={position}
    />
  );
};

const MinecraftLandscape = () => {
  return (
    <Canvas
      camera={{ position: [0, 0, 0], fov: 50 }}
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    >
      <Suspense fallback={null}>
        <MinecraftModel scale={[1, 1, 1]} position={[0, -50, 0]} />
        <OrbitControls enableZoom={false} enablePan={false} />
      </Suspense>
    </Canvas>
  );
};

export default MinecraftLandscape;